.wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    position: relative;
}
.leftBox {
    margin-right: 90px;
}
.rightBox {
    width: 100%;
}
.regionSection {
    margin-left: 20px;
}
.addButton {
    color: #E17500;
    margin-bottom: 30px;
    width: 250px;
}
.addLocationButton {
    content: '';
    color: #E17500;
    height: 32px;
}
.addAreaButton {
    content: '';
    color: #E17500;
    height: 32px;
}
.addLocalButton {
    content: '';
    color: #E17500;
    height: 32px;
}
.title {
    margin: 0 0 32px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: left;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #000000
}
.destinationItem {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    display: flex;
}
.regionsLink {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
}
.arrowRightIcon {
    position: absolute;
    top: 1px;
    left: 0;
    width: 20px;
    height: 20px;
}
.regionItem {
    margin-left: 30px;
}
.absent {
    margin-left: 25px;
}

