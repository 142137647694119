.title {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: left;
    letter-spacing: 1.25px;
    color: #000000;
    margin: 0 0 25px 0px;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.buttons {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
}
.header {
    display: flex;
    align-items: center;
}
.inputField {
    height: 75px;
}
.checkboxField {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}
.label {
    font-weight: 500;
    margin-right: 10px;
    margin-top: 9px;
}
.box {
    margin: 10px 0 0 2px;
}
.levelWrapper,
.languagesWrapper,
.currencyWrapper,
.timeWrapper {
    margin-bottom: 30px;
}
.levelBox,
.languagesBox,
.currencyBox {
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 15px;
}
.levelItem,
.languagesItem,
.currencyItem {
    width: 68px;
    height: 40px;
    border: 1px solid #000;
    border-radius: 4px;
    margin: 0 10px 10px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgb(248, 248, 248);
    border: none;
    box-shadow: 1px 1px 5px #000;
}
.closeIcon {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    cursor: pointer;
}
.levelTitle,
.languagesTitle,
.currencyTitle {
    margin-bottom: 10px;
    font-weight: 500;
    margin-top: 15px;
}
.currencyAddBox {
    width: 175px;
    display: flex;
    justify-content: flex-start;
}
.languageAddBox {
    width: 260px;
    display: flex;
    justify-content: flex-start;
}
.addBox {
    display: flex;
    justify-content: flex-start;
}
.addBoxTimeZone {
    width: 410px;
    display: flex;
    justify-content: flex-start;
}
.addIcon {
    margin-right: 10px;
}