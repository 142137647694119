.wrapper {
    width: 100%;
    display: flex;
    margin-top: 40px;
    position: relative;
}
.leftBox {
    min-width: 400px;
    margin-right: 100px;
}
.rightBox {
    min-width: 750px;
}  
.addButton {
    color: #E17500;
    margin-bottom: 15px;
    width: 250px;
}
.absent {
    margin: 25px 0 0 25px;
}
.facilityLink {
    display: flex;
    align-items: center;
    padding: 0 0 0 5px;
    cursor: pointer;
    width: 180px;
    height: 32px;
    border-radius: 8px;
}
.facilityLink:hover {
    background-color: #f1ecec;
}

