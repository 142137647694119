.wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    position: relative;
}
.leftBox {
    min-width: 460px;
    margin-right: 100px;
}
.rightBox {
    min-width: 750px;
}  
.addButton {
    color: #E17500;
    margin-bottom: 30px;
    width: 250px;
}
.absent {
    border-top: 1px solid #CDD2D7;
    padding: 25px 0 0 15px;
    width: 460px;
}
.searchBox {
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
}
.searchSelect {
    width: 150px;
    margin-right: 10px;
}
.search {
    width: 300px;
}

.categoryLink {
    display: flex;
    align-items: center;
    padding: 0 0 0 5px;
    cursor: pointer;
    width: 180px;
    height: 32px;
    border-radius: 8px;
}
.categoryLink:hover {
    background-color: #f1ecec;
}

.header{
    display: flex;
    justify-content: flex-start;
}

.headerStatus {
    border: 1px solid #CDD2D7;
    padding: 5px 7px;
    border-right: none;
    width: 100px;
    font-weight: 500;
    border-bottom: none;
    border-right: none;
}

.headerOrder {
    border: 1px solid #CDD2D7;
    padding: 5px 7px;
    border-right: none;
    width: 110px;
    text-align: center;
    font-weight: 500;
    border-bottom: none;
    border-right: none;
}

.headerName {
    border: 1px solid #CDD2D7;
    padding: 5px 7px;
    width: 260px;
    font-weight: 500;
    border-bottom: none;
}

.status {
    border: 1px solid #CDD2D7;
    padding: 5px 7px;
    border-bottom: none;
    border-right: none;
    width: 100px;
}

.active {
    background-color: #FFA94C;
}

.inactive {
    background-color: #32D7F0;
}

.order {
    border: 1px solid #CDD2D7;
    padding: 5px 7px;
    border-bottom: none;
    border-right: none;
    width: 110px;
    text-align: center;
}

.name {
    border: 1px solid #CDD2D7;
    padding: 5px 7px;
    width: 260px;
    border-bottom: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

