.title {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: left;
    letter-spacing: 1.25px;
    color: #000000;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.wrapper {
    width: 100%;
    color: #E17500;
    padding: 0;
}
.textBox {
    width: 9px;
    color: #E17500;
    margin-bottom: 20px;
    padding: 0;
}
.iconBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 215px;  
    color: #E17500;
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    padding: 15px 15px;
    position: relative;
}
.helperText {
    content: '';
    margin-bottom: 10px;
    top: 7px;
    right: 0;
    font-weight: 400;
    font-size: 13px;
    color:rgba(0, 0, 0, 0.80);
}
.label {
    margin: 0 0 7px;
    color: rgba(0, 0, 0, 0.6);
}
.icon {
    width: 130px;
    height: 130px;
}
.rightIcon {
    margin: auto 0;
    width: 120px;
    height: 120px;
    border: 1px solid #bbb4b4;
}
.rightIcon img {
    width: 100%;
    height: 100%;
}
.icon img{
    width: 100%;
    height: 100%;
}
.absent {
    color: rgba(0, 0, 0, 0.26);
}