.wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    position: relative;
}
.leftBox {
    max-width: 600px;
    width: 100%;
    margin-right: 50px;
}
.rightBox {
    width: 100%;
}  
.addButton {
    color: #E17500;
    margin-bottom: 30px;
    width: 250px;
}
.absent {
    border-top: 1px solid #CDD2D7;
    padding: 25px 0 0 15px;
    width: 460px;
}

.categoryLink {
    display: flex;
    align-items: center;
    padding: 0 0 0 5px;
    cursor: pointer;
    width: 180px;
    height: 32px;
    border-radius: 8px;
}
.categoryLink:hover {
    background-color: #f1ecec;
}
.searchBox {
    margin: 25px 0;
    display: flex;
}

.search {
    position: relative;
    width: 340px;
}

.searchInput {
    background-color: #fff;
    font-size: 14px;
    color: rgb(51, 51, 51);
    width: 180px;
    height: 35px;
    border-radius: 3.5px;
    border: 1px solid #CDD2D7;
    text-align: left;
    outline: none;
    padding-left: 10px;
}

.searchInput::placeholder {
    font-family: 'Open Sans', 'sans-serif';
}

.searchSelect {
    width: 150px;
    margin-right: 10px;
}