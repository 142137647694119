@use './variables' as vars;
@use './mixins' as mixins;
@use "sass:map";

.itemRow {
    border: vars.$noBorder;
    padding: vars.$noSpacing;
    margin: vars.$noSpacing;
    display: flex; 
    justify-content: "space-between";
    width: 100%;
}

.itemCell {
    border: vars.$noBorder;
    padding: vars.$noSpacing;
    margin: vars.$noSpacing;
    height: vars.$tableRowHeight;
}

.accordion {
    padding: vars.$noSpacing;
    margin: vars.$noSpacing;
    width: vars.$tableWidth;
}

.accordionTitle {
    height: vars.$tableRowHeight;
    display:"flex"; 
    flex-direction: "row"; 
    padding: vars.$noSpacing;
    margin: vars.$noSpacing;
    &:hover {
        background-color: map-get(vars.$colors, "orange");
    }
    div {
        padding: vars.$noSpacing;
        margin: vars.$noSpacing;
    }
}

.accTitleCell {
    @include mixins.cell;
}

.backgroundOrange {
    background-color: map-get(vars.$colors, "orange") !important;
}

.backgroundBlue {
    background-color: map-get(vars.$colors, "cyan") !important;
}

.backgroundGrey {
    background-color: map-get(vars.$colors, "grey") !important;
}

.backgroundRed {
    background-color: map-get(vars.$colors, "red") !important;
}