.title {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: left;
    letter-spacing: 1.25px;
    color: #000000;
    margin: 0 0 25px 0px;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.buttons {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
}
.header {
    display: flex;
}
.imageBox {
    display: flex;
    justify-content: start;
    width: 100%;
    height: 215px;
    color: #E17500;
    margin-bottom: 53px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    padding: 15px 15px;
    position: relative;
}
.imageBoxError {
    display: flex;
    justify-content: start;
    width: 100%;
    height: 215px;
    color: #E17500;
    border: 1px solid #d3302f;
    margin-bottom: 5px;
    border-radius: 4px;
    padding: 15px 15px;
    position: relative;
}
.imageBoxErrorText {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 25px;
    margin-left: 14px;
    color: #d43131;
    width: 300px;
    margin-left: 0px;
}
.imageLabel {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 7px;
    margin-left: 1px;
}
.helperText {
    content: '';
    margin-bottom: 10px;
    top: 7px;
    right: 0;
    font-weight: 400;
    font-size: 13px;
    color:rgba(0, 0, 0, 0.80);
}
.icon {
    height: 120px;
    width: 120px;
}
.icon img {
    width: 100%;
    height: 100%;
}