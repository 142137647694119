@use './variables' as vars;
@use './mixins' as mixins;

.tableContainer {
    width: vars.$pageContainerWidth;
}

.table {
    min-width: vars.$tableWidth;
    border: vars.$border;
    border-collapse: collapse !important;
    width: vars.$tableWidth;
}

.tableRow {
    display: flex !important; 
    width: 100%;
    height: vars.$tableRowHeight;
    justify-content: space-between;
    border: vars.$noBorder;
}

.tableCell {
    @include mixins.cell;
}

