.wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    position: relative;
}
.leftBox {
    min-width: 400px;
    margin-right: 100px
}
.rightBox {
    width: 100%;
}  
.addButton {
    color: #E17500;
    margin-bottom: 30px;
    width: 250px;
}
.absent {
    margin: 0 0 0 8px;
}
.search {
    margin-bottom: 15px;
}
.input {
    margin-top: 35px;
}
.searchInput {
    font-family: 'Open Sans', 'sans-serif';
    font-size: 14px;
    color: rgb(51, 51, 51);
    width: 100%;
    border-radius: 3.5px;
    text-align: left;
}
.searchInput::placeholder {
    font-family: 'Open Sans', 'sans-serif';
}
.searchIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: #cfcaca;
}