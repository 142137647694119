.notifications-enter {
  opacity: 0;
}
.notifications-enter-active {
  opacity: 1;
  transition: all 300ms;
}
.notifications-enter-done  {
  opacity: 1;
  transition: all 300ms;
}
.notifications-exit {
  opacity: 1;
}
.notifications-exit-active {
  opacity: 0;
  transition: all 300ms;
}