.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 45px 0 30px;
}
.title {
  font-size: 20px;
  font-weight: 500;
}
.subtitle {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
}
.box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}
.typeBox {
  width: 242.5px;
}
.description {
  width: 140px;
}
.disabled {
  color: rgba(0, 0, 0, 0.38);
  width: 140px;
}
.period {
  display: flex;
  flex-direction: column;
}
.valid {
  margin-bottom: 10px;
}
.searchInput {
  background-color: #fff;
  font-size: 14px;
  color: rgb(51, 51, 51);
  width: 350px;
  height: 40px;
  border: 1px solid #cdd2d7;
  text-align: left;
  outline: none;
  padding-left: 10px;
}
.timeContainer {
  margin: 20px 0;
}
.row {
  display: flex;
  width: 1100px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}
.day {
  width: 130px;
  padding: 7px;
  border: 1px solid #000;
  border-radius: 4.5px;
  margin-right: 15px;
}
.timeBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 20px;
}
.timeItem {
  width: 60px;
  height: 35px;
  border: 1px solid #000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgb(248, 248, 248);
  border: none;
  box-shadow: 1px 1px 5px #000;
}
.timeItemDisabled {
  width: 60px;
  height: 35px;
  border: 1px solid #000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgb(248, 248, 248);
  border: none;
  box-shadow: 1px 1px 5px #000;
  opacity: 0.7;
}
.dash {
  font-size: 25px;
  margin: 0 10px 5px 10px;
}
.buttons {
  display: flex;
}
.closeButtons {
  display: flex;
  width: 140px;
}
.closeButtonsWithoutCopy {
  display: flex;
}
.clearIcon {
  margin-top: -22px;
  margin-left: 5px;
  cursor: pointer;
}
.closedBox {
  width: 100px;
  height: 35px;
  border: 1px solid #000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgb(248, 248, 248);
  border: none;
  box-shadow: 1px 1px 5px #000;
}

.closedBoxDisabled {
  width: 100px;
  height: 35px;
  border: 1px solid #000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgb(248, 248, 248);
  border: none;
  box-shadow: 1px 1px 5px #000;
  opacity: 0.7;
}

.specialBox {
  display: flex;
  align-items: center;
  width: 1000px;
  cursor: pointer;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 15px;
}
.optionsBox {
  display: flex;
  flex-direction: column;
}

.temporaryClosedBox {
  display: flex;
}

.groupBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.temporaryClosed {
  margin-right: 10px;
  width: 200px;
}
.lastEntryBox {
  display: flex;
  align-items: center;
  width: 100px;
}
.specialTimeBox {
  width: 180px;
  margin-right: 10px;
}
.specialDateBox {
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.specialDate {
  display: flex;
}

.closedOn {
  margin: 10px 10px 10px 25px;
}

.timeBoxDisabled {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 20px;
  pointer-events: none;
}
