$white: #fff !important;

@mixin button {
    height: 36.5px;
    width: 100%;
}

.formButton {
    @include button;
}

.saveButton {
    @include button;
    color: $white;
    min-width: fit-content !important;
}

.addButton {
    @include button;
    color: $white;
}

.translationTabsContainer {
    width: 520px;
    height: 100px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: center;
  }
  
.button {
    background-color: black;
}