$border: 1px solid #CDD2D7;//1px solid black !important;
$noBorder: none !important;
$noSpacing: 0 !important;
$tableRowHeight: 57px !important;
$pageContainerWidth: 1270px !important;
$tableWidth: 1266px !important;
$colors: (
    "orange": #FFA94C,
    "cyan": #32D7F0,
    "grey": #A0A0A0,
    "red": #E84E33
)