@use './variables' as vars;

@mixin cell {
    height: vars.$tableRowHeight;
    border-right: vars.$border;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    &:nth-child(4) {
        width: 100%;
        max-width: 400px;
    }
    &:first-child {
        width: 20%;
    }
    &:last-child {
        width: 15%;
        border-right: vars.$noBorder;
    }
}