@use "sass:map";

$colors: (
    "white": #fff,
    "orange": #FFA94C
);

.wrapper {
    width: 100%;
}

.tabsContainer {
    width: 100%;
    min-width: 320px;
    background-color: map-get($colors, "white");
    border-radius: 8px;
    margin-top: 16px;
    & .MuiTabs-flex-container {
        justify-content: flex-start;
    };
    & .MuiTab-root {
        margin-right: 7px;
    };
}

.tab {
    color: map-get($colors, "white");
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: rgba(0,0,0,0.51);
    width: 100%;
    max-width: 50px;
    min-width: 20px;
    max-height: 36px;
    min-height: 20px;
    padding: 12px 16px;
    margin: 6px 0px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin-right: 7px;
    &:hover {
        background-color: rgba(0,0,0, 0.40);
    };
    &:focus {
        color: #fff;
        border-radius: 3px;
        outline: 2px solid map-get($colors, "orange");
        outline-offset: 2px;
    };
    &:first-child {
        margin-left: 4px;
    };
    &:last-child {
        margin-right: 4px;
    };
    &:global(.Mui-selected){
        background-color: map-get($colors, "orange");
        color: map-get($colors, "white");
        border-radius: 3px;
        outline: 2px solid map-get($colors, "orange");
        outline-offset: 2px;
    };
    &.disabled {
        opacity: 0.5;
        cursor:  not-allowed;
    };
}

.tabPanel {
    width: 100%;
    font-size: 0.875rem;
    div {
        padding: 0;
    };
};

.rowLabel {
    white-space: nowrap;
}

.rowField {
    display: flex;
    div {
        width: 100%;
    }
    
    input {
        -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
    }
}
